import React from "react";
import Bredcom from "../Bredcrumb/Main";
import "./NotFound.css";
function NotFound() {
  return (
    <>
      <Bredcom subtitle="Not Found" />
      <div className="notFound">
        <h2>404 Page Not Found</h2>
        <p>We're sorry, but the page you requested could not be found.</p>
      </div>
    </>
  );
}

export default NotFound;
