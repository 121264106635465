import React, { useState } from "react";
import "./Tabs.css";
import { useNavigate } from "react-router-dom";

const Tab = ({ activeTab, label, onClick }) => {
  const handleClick = () => {
    onClick(label);
  };

  return (
    <div
      className={`tab ${activeTab === label ? "active" : ""}`}
      onClick={handleClick}
    >
      {label}
    </div>
  );
};

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label);
  const navigate = useNavigate();

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className="tabs">
      <div className="tab-list">
        {children.map((child) => (
          <Tab
            key={child.props.label}
            activeTab={activeTab}
            label={child.props.label}
            onClick={onClickTabItem}
          />
        ))}
        <div className="tab" onClick={handleLogout}>
          Logout
        </div>
      </div>
      <div className="tab-content">
        {children.map((child) => {
          if (child.props.label !== activeTab) return null;
          return child;
        })}
      </div>
    </div>
  );
};

export default Tabs;
