import React, { useState } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import FsLightbox from "fslightbox-react";
// import galleryone from "../../assets/images/resource/gallery-1.jpg";
// import gallerytwo from "../../assets/images/resource/gallery-2.jpg";
// import gallerythree from "../../assets/images/resource/gallery-3.jpg";
import about1 from "../../assets/images/about1.jpeg";
import about2 from "../../assets/images/about2.jpeg";
import about3 from "../../assets/images/about3.jpg";
import about4 from "../../assets/images/about4.jpg";
import about5 from "../../assets/images/about5.jpg";
import about6 from "../../assets/images/about6.jpg";
import about7 from "../../assets/images/about7.jpg";
import about8 from "../../assets/images/about8.jpg";
import about9 from "../../assets/images/about9.jpg";
import about10 from "../../assets/images/about10.jpg";
import about11 from "../../assets/images/about11.jpg";
import about12 from "../../assets/images/about12.jpg";
import about13 from "../../assets/images/about13.jpg";
import about14 from "../../assets/images/about14.jpeg";
import about15 from "../../assets/images/about15.jpeg";

function Sliderabout() {
  const [images, setImages] = useState([
    about1,
    about2,
    about3,
    about4,
    about5,
    about6,
    about7,
    about8,
    about9,
    about10,
    about11,
    about12,
    about13,
    about14,
    about15,
  ]);

  // const [img, setImg] = useState();
  // const [img1, setImg1] = useState();
  // const [img2, setImg2] = useState();
  // const [img3, setImg3] = useState();
  // const [img4, setImg4] = useState();
  // const [img5, setImg5] = useState();

  const [toggler, setToggler] = useState(false);

  const [activeImage, setActiveImage] = useState(1);

  const sliderCard = {
    loop: true,
    items: 1,
    navText: [
      '<span class="icon fa-light fa-angle-left"></span>',
      '<span class="icon fa-light fa-angle-right"></span>',
    ],
    margin: 30,
    dots: true,
    nav: true,
    smartSpeed: 1000,
    autoplay: true,
    responsive: {
      0: {
        margin: 0,
        items: 1,
      },
      576: {
        margin: 30,
        items: 1,
      },
      768: {
        margin: 30,
        items: 1,
      },
      992: {
        margin: 30,
        items: 1,
      },
      1200: {
        margin: 30,
        items: 1,
      },
    },
  };

  return (
    <>
      <section className="image-gallery">
        <div className="carousel-box">
          <div className="auto-container">
            <OwlCarousel
              className="image-gallery-slider owl-dot-style-one owl-carousel owl-theme"
              {...sliderCard}
              data-owl-options="{}"
            >
              <div className="gallery-block">
                <div className="image">
                  <Link
                    to=""
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <figure
                      className="gallery-image"
                      onClick={() => {
                        setToggler(!toggler);
                        setActiveImage(1);
                      }}
                    >
                      <img src={about1} alt="" />
                    </figure>
                  </Link>
                </div>
              </div>

              <div className="gallery-block item">
                <div className="image">
                  <Link
                    to=""
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <figure
                      className="gallery-image"
                      onClick={() => {
                        setToggler(!toggler);
                        setActiveImage(2);
                      }}
                    >
                      <img src={about2} alt="" />
                    </figure>
                  </Link>
                </div>
              </div>

              <div className="gallery-block item">
                <div className="image">
                  <Link
                    to=""
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <figure
                      className="gallery-image"
                      onClick={() => {
                        setToggler(!toggler);
                        setActiveImage(3);
                      }}
                    >
                      <img src={about3} alt="" />
                    </figure>
                  </Link>
                </div>
              </div>

              <div className="gallery-block item">
                <div className="image">
                  <Link
                    to=""
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <figure
                      className="gallery-image"
                      onClick={() => {
                        setToggler(!toggler);
                        setActiveImage(4);
                      }}
                    >
                      <img src={about4} alt="" />
                    </figure>
                  </Link>
                </div>
              </div>

              <div className="gallery-block item">
                <div className="image">
                  <Link
                    to=""
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <figure
                      className="gallery-image"
                      onClick={() => {
                        setToggler(!toggler);
                        setActiveImage(5);
                      }}
                    >
                      <img src={about5} alt="" />
                    </figure>
                  </Link>
                </div>
              </div>

              <div className="gallery-block item">
                <div className="image">
                  <Link
                    to=""
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <figure
                      className="gallery-image"
                      onClick={() => {
                        setToggler(!toggler);
                        setActiveImage(6);
                      }}
                    >
                      <img src={about6} alt="" />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="gallery-block item">
                <div className="image">
                  <Link
                    to=""
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <figure
                      className="gallery-image"
                      onClick={() => {
                        setToggler(!toggler);
                        setActiveImage(7);
                      }}
                    >
                      <img src={about7} alt="" />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="gallery-block item">
                <div className="image">
                  <Link
                    to=""
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <figure
                      className="gallery-image"
                      onClick={() => {
                        setToggler(!toggler);
                        setActiveImage(8);
                      }}
                    >
                      <img src={about8} alt="" />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="gallery-block item">
                <div className="image">
                  <Link
                    to=""
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <figure
                      className="gallery-image"
                      onClick={() => {
                        setToggler(!toggler);
                        setActiveImage(9);
                      }}
                    >
                      <img src={about9} alt="" />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="gallery-block item">
                <div className="image">
                  <Link
                    to=""
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <figure
                      className="gallery-image"
                      onClick={() => {
                        setToggler(!toggler);
                        setActiveImage(10);
                      }}
                    >
                      <img src={about10} alt="" />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="gallery-block item">
                <div className="image">
                  <Link
                    to=""
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <figure
                      className="gallery-image"
                      onClick={() => {
                        setToggler(!toggler);
                        setActiveImage(11);
                      }}
                    >
                      <img src={about11} alt="" />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="gallery-block item">
                <div className="image">
                  <Link
                    to=""
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <figure
                      className="gallery-image"
                      onClick={() => {
                        setToggler(!toggler);
                        setActiveImage(12);
                      }}
                    >
                      <img src={about12} alt="" />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="gallery-block item">
                <div className="image">
                  <Link
                    to=""
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <figure
                      className="gallery-image"
                      onClick={() => {
                        setToggler(!toggler);
                        setActiveImage(13);
                      }}
                    >
                      <img src={about13} alt="" />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="gallery-block item">
                <div className="image">
                  <Link
                    to=""
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <figure
                      className="gallery-image"
                      onClick={() => {
                        setToggler(!toggler);
                        setActiveImage(14);
                      }}
                    >
                      <img src={about14} alt="" />
                    </figure>
                  </Link>
                </div>
              </div>
              <div className="gallery-block item">
                <div className="image">
                  <Link
                    to=""
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <figure
                      className="gallery-image"
                      onClick={() => {
                        setToggler(!toggler);
                        setActiveImage(15);
                      }}
                    >
                      <img src={about15} alt="" />
                    </figure>
                  </Link>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>

      <FsLightbox toggler={toggler} sources={images} slide={activeImage} />
    </>
  );
}

export default Sliderabout;
