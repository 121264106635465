import React from "react";
import Aboutsec from "../Menabout/Aboutsec";
import Aboutservice from "../Menabout/Aboutservice";
import Aboutwhyus from "../Menabout/Aboutwhyus";
import Sliderabout from "../Menabout/Sliderabout";
import Testimonialsabout from "../Menabout/Testimonialsabout";
import Winningchef from "../Menabout/Winningchef";
import Bredcrumb from "../Bredcrumb/Main";
import Img from "../../assets/images/WEBSITE PHOTOS/DRINKS/summer-healthy-cocktails-2022-02-02-04-50-44-utc.jpg";

function Main() {
  return (
    <>
      <Bredcrumb subtitle="About Us" title="OUR STORY" Img={Img} />

      <Aboutsec />
      <Testimonialsabout />
      <Aboutwhyus />
      <Sliderabout />
    </>
  );
}

export default Main;
