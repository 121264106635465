import React from "react";
import Tabs from "./Tabs";
import { useEffect } from "react";
import { useState } from "react";
import ReservationCard from "./ReservationCard";
// import TableCard from "./TableCard";
import MenuItem from "./MenuItem";
import { useNavigate } from "react-router-dom";
import SpecialDish from "./SpecialDish";

function Admin() {
  const [reservations, setReservations] = useState([]);
  // const [tables, setTables] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/reservations`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => setReservations(data.reverse()))
      .catch((error) => console.error(error));

    // fetch(`${process.env.REACT_APP_API_URL}/api/tables`, {
    //   method: "GET",
    // })
    //   .then((response) => response.json())
    //   .then((data) => setTables(data))
    //   .catch((error) => console.error(error));

    fetch(`${process.env.REACT_APP_API_URL}/api/menus`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => setMenuItems(data))
      .catch((error) => console.error(error));
  }, []);

  const deleteReservation = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/reservations/${id}`,
        {
          method: "DELETE",
        }
      );
      const data = await response.json();
      console.log(data);
      setReservations((prevReservations) =>
        prevReservations.filter((reservation) => reservation._id !== id)
      );
    } catch (err) {
      console.error(err);
    }
  };
  const deleteMenuItem = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/menus/${id}`,
        {
          method: "DELETE",
        }
      );
      const data = await response.json();
      console.log(data);
      setMenuItems((prevMenuItems) =>
        prevMenuItems.filter((menuItem) => menuItem._id !== id)
      );
    } catch (err) {
      console.error(err);
    }
  };

  // const updateTable = async (id) => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/api/tables/${id}`,
  //       {
  //         method: "PUT",
  //       }
  //     );
  //     const data = await response.json();
  //     const updatedTables = tables.filter((table) => table._id !== data._id);
  //     setTables([...updatedTables, data]);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  return (
    <div>
      <Tabs>
        <div label="Reservations">
          <h2>Reservations</h2>
          {reservations.map((reservation) => (
            <ReservationCard
              key={reservation._id}
              reservation={reservation}
              deleteReservation={deleteReservation}
            />
          ))}
        </div>

        <div label="Menu">
          <div className="menuTitle">
            <h2>Menu Items</h2>
            <i
              style={{
                backgroundColor: "rgb(228, 197, 144)",
                padding: "10px",
                borderRadius: "20px",
                color: "black",
              }}
              onClick={() => navigate("/admin/addMenu")}
              class="fa-solid fa-plus"
            ></i>
          </div>
          <div className="menuItems">
            {menuItems.map((menuItem) => (
              <MenuItem
                key={menuItem._id}
                menuItem={menuItem}
                deleteMenuItem={deleteMenuItem}
              />
            ))}
          </div>
        </div>
        <div label="Special Dish">
          <div className="menuTitle">
            <h2>Today's Special Dish</h2>
            <i
              style={{
                backgroundColor: "rgb(228, 197, 144)",
                padding: "10px",
                borderRadius: "20px",
                color: "black",
              }}
              onClick={() => navigate("/admin/addSpecial")}
              class="fa-solid fa-plus"
            ></i>
          </div>

          <SpecialDish />
        </div>
      </Tabs>
    </div>
  );
}

export default Admin;
