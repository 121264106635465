import React, { useState } from "react";
import { Link } from "react-router-dom";
import bgone from "../../assets/images/background/bg-5.png";
import Bgtwo from "../../assets/images/background/bg-6.png";
import { useEffect } from "react";
// import { useTranslation } from "react-i18next";

// <li
//                   className={`tab-btn ${tabMenu.d && "active-btn"}`}
//                   onClick={() => tabActive({ d: true })}
//                   data-tab="#tab-4"
//                 >
//                   <span>WINES</span>
//                 </li>

function Menusection() {
  const [tabMenu, tabActive] = useState({ a: true });
  const [menuItems, setMenuItems] = useState([]);
  const Main = menuItems.filter((item) => item.category === "main");
  const Pizza = menuItems.filter((item) => item.category === "pizzeria");
  const Sides = menuItems.filter((item) => item.category === "side");
  const Drinks = menuItems.filter((item) => item.category === "drinks");
  // const { t } = useTranslation();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/menus`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => setMenuItems(data))
      .catch((error) => console.error(error));
  }, []);

  return (
    <>
      <section className="menu-section">
        <div className="left-bg">
          <img src={bgone} alt="" title="" />
        </div>

        <div className="right-bg">
          <img src={Bgtwo} alt="" title="" />
        </div>

        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>SPECIAL SELECTION</span>
            </div>

            <div className="pattern-image">
              <img
                src={require("../../assets/images/icons/separator.svg").default}
                alt="mySvgImage"
              />
            </div>

            <h2>Delicious Menu</h2>
          </div>

          <div className="tabs-box menu-tabs">
            <div className="buttons">
              <ul className="tab-buttons clearfix">
                <li
                  className={`tab-btn ${tabMenu.a && "active-btn"}`}
                  onClick={() => tabActive({ a: true })}
                  data-tab="#tab-1"
                >
                  <span>Main Menu</span>
                </li>
                <li
                  className={`tab-btn ${tabMenu.b && "active-btn"}`}
                  onClick={() => tabActive({ b: true })}
                  data-tab="#tab-2"
                >
                  <span>Pizza</span>
                </li>
                <li
                  className={`tab-btn ${tabMenu.c && "active-btn"}`}
                  onClick={() => tabActive({ c: true })}
                  data-tab="#tab-3"
                >
                  <span>Side Dishes & Salads</span>
                </li>
                <li
                  className={`tab-btn ${tabMenu.d && "active-btn"}`}
                  onClick={() => tabActive({ d: true })}
                  data-tab="#tab-4"
                >
                  <span>Drinks & Sweets</span>
                </li>
              </ul>
            </div>
            <div className="tabs-content">
              <div className={`tab ${tabMenu.a && "active-tab"}`} id="tab-1">
                <div className="row clearfix">
                  <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                      {Main?.slice(0, 5).map((item) => (
                        <div className="dish-block">
                          <div className="inner-box">
                            <div className="dish-image">
                              <Link to="#">
                                <img
                                  src={`${process.env.REACT_APP_API_URL}/assets/${item.image}`}
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="title clearfix">
                              <div className="ttl clearfix">
                                <h5>
                                  <Link to="#">{item.name}</Link>
                                </h5>
                              </div>
                              <div className="price">
                                <span>{item.price} €</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                      {Main?.slice(5, 10).map((item) => (
                        <div className="dish-block">
                          <div className="inner-box">
                            <div className="dish-image">
                              <Link to="#">
                                <img
                                  src={`${process.env.REACT_APP_API_URL}/assets/${item.image}`}
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="title clearfix">
                              <div className="ttl clearfix">
                                <h5>
                                  <Link to="#">{item.name}</Link>
                                </h5>
                              </div>
                              <div className="price">
                                <span>{item.price} €</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className={`tab ${tabMenu.b && "active-tab"}`} id="tab-2">
                <div className="row clearfix">
                  <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                      {Pizza?.slice(0, 5).map((item) => (
                        <div className="dish-block">
                          <div className="inner-box">
                            <div className="dish-image">
                              <Link to="#">
                                <img
                                  src={`${process.env.REACT_APP_API_URL}/assets/${item.image}`}
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="title clearfix">
                              <div className="ttl clearfix">
                                <h5>
                                  <Link to="#">{item.name}</Link>
                                </h5>
                              </div>
                              <div className="price">
                                <span>{item.price} €</span>
                              </div>
                            </div>
                            <div className="text desc">
                              <Link to="#">{item.description}</Link>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                      {Pizza?.slice(5, 10).map((item) => (
                        <div className="dish-block">
                          <div className="inner-box">
                            <div className="dish-image">
                              <Link to="#">
                                <img
                                  src={`${process.env.REACT_APP_API_URL}/assets/${item.image}`}
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="title clearfix">
                              <div className="ttl clearfix">
                                <h5>
                                  <Link to="#">{item.name}</Link>
                                </h5>
                              </div>
                              <div className="price">
                                <span>{item.price} €</span>
                              </div>
                            </div>
                            <div className="text desc">
                              <Link to="#">{item.description}</Link>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className={`tab ${tabMenu.c && "active-tab"}`} id="tab-3">
                <div className="row clearfix">
                  <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                      {Sides?.slice(0, 5).map((item) => (
                        <div className="dish-block">
                          <div className="inner-box">
                            <div className="dish-image">
                              <Link to="#">
                                <img
                                  src={`${process.env.REACT_APP_API_URL}/assets/${item.image}`}
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="title clearfix">
                              <div className="ttl clearfix">
                                <h5>
                                  <Link to="#">{item.name}</Link>
                                </h5>
                              </div>
                              <div className="price">
                                <span>{item.price} €</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                      {Sides?.slice(5, 10).map((item) => (
                        <div className="dish-block">
                          <div className="inner-box">
                            <div className="dish-image">
                              <Link to="#">
                                <img
                                  src={`${process.env.REACT_APP_API_URL}/assets/${item.image}`}
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="title clearfix">
                              <div className="ttl clearfix">
                                <h5>
                                  <Link to="#">{item.name}</Link>
                                </h5>
                              </div>
                              <div className="price">
                                <span>{item.price} €</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className={`tab ${tabMenu.d && "active-tab"}`} id="tab-3">
                <div className="row clearfix">
                  <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                      {Drinks?.slice(0, 5).map((item) => (
                        <div className="dish-block">
                          <div className="inner-box">
                            <div className="dish-image">
                              <Link to="#">
                                <img
                                  src={`${process.env.REACT_APP_API_URL}/assets/${item.image}`}
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="title clearfix">
                              <div className="ttl clearfix">
                                <h5>
                                  <Link to="#">{item.name}</Link>
                                </h5>
                              </div>
                              <div className="price">
                                <span>{item.price} €</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                    <div className="inner">
                      {Drinks?.slice(5, 10).map((item) => (
                        <div className="dish-block">
                          <div className="inner-box">
                            <div className="dish-image">
                              <Link to="#">
                                <img
                                  src={`${process.env.REACT_APP_API_URL}/assets/${item.image}`}
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="title clearfix">
                              <div className="ttl clearfix">
                                <h5>
                                  <Link to="#">{item.name}</Link>
                                </h5>
                              </div>
                              <div className="price">
                                <span>{item.price} €</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="open-timing">
            <div className="hours">
              Daily from <span className="theme_color">06:00 am</span> to{" "}
              <span className="theme_color">03:00 am</span>
            </div>
            <div className="link-box">
              <Link to="/menu" className="theme-btn btn-style-two clearfix">
                <span className="btn-wrap">
                  <span className="text-one">view all menu</span>
                  <span className="text-two">view all menu</span>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Menusection;
