import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Bgone from "../../../assets/images/WEBSITE PHOTOS/Different photos/assorted-chinese-dishes-2021-08-30-14-44-13-utc.jpg";
// import Bgtwo from "../../../assets/images/background/image-11.jpg";
import { useRef } from "react";

function Reservation() {
  const [persons, setPersons] = useState("1");
  const [time, setTime] = useState("");
  const [availableHours, setAvailableHours] = useState([]);
  const [message, setMessage] = useState("");

  const [date, setDate] = useState("");
  const [name, setName] = useState("");
  const [reservationID, setReservationID] = useState("");

  const [phone, setPhone] = useState("");
  // const [tableId, setTableId] = useState("");
  // const [tables, setTables] = useState([]);
  const navigate = useNavigate();
  const minDate = new Date().toISOString().slice(0, 10);

  function generateReservationId() {
    const timestamp = Date.now().toString().slice(-6); // get last 6 digits of current timestamp
    const randomNumber = Math.floor(Math.random() * 1000000)
      .toString()
      .padStart(6, "0"); // generate a random number between 0 and 999999 and pad it with leading zeros to ensure it has 6 digits
    const reservationId = timestamp + randomNumber; // combine timestamp and random number to create a unique ID
    setReservationID(reservationId);
  }

  const getActualTime = () => {
    const now = new Date();
    const threeAM = new Date(now);
    threeAM.setHours(3, 0, 0, 0);
    if (now.getHours() >= 3) {
      threeAM.setDate(threeAM.getDate() + 1);
    }
    const hours = [];
    while (now < threeAM) {
      hours.push(now.getHours());
      now.setHours(now.getHours() + 1);
    }

    setAvailableHours(hours);
    setTime(hours[1]);
    return hours;
  };

  const reservation = {
    name,
    phone,
    date,
    time,
    persons,
    reservationID,
    message,
  };
  const inputRef = useRef();

  const handlePersonsChange = (event) => {
    setPersons(event.target.value);
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  // const handleTableChange = (event) => {
  //   setTableId(event.target.value);
  // };
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  useEffect(() => {
    // fetch(`${process.env.REACT_APP_API_URL}/api/check-availability`, {
    //   method: "GET",
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setTables(data);
    //     setTableId(data[0]._id);
    //   })
    //   .catch((error) => console.error(error));
    getActualTime();
    generateReservationId();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_API_URL}/api/reservations`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reservation),
    })
      .then((response) => response.json())
      .then((data) => navigate(`/reservation-confirmation/${data._id}`))
      .catch((error) => console.error(error));
  };
  return (
    <>
      <section className="reserve-section style-two">
        <div
          className="image-layer"
          style={{ backgroundImage: `url(${Bgone})` }}
        ></div>
        <div className="auto-container">
          <div className="outer-box">
            <div className="row clearfix">
              <div className="reserv-col col-lg-8 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="title">
                    <h2>Online Reservation</h2>
                    <div className="request-info">
                      Booking request{" "}
                      <a href="tel:+39-064-875-321">+39-064-875-321</a> or fill
                      out the order form
                    </div>
                  </div>
                  <div className="default-form reservation-form">
                    <form onSubmit={handleSubmit}>
                      <div className="row clearfix">
                        <div className="form-group col-lg-6 col-md-6 col-sm-12">
                          <div className="field-inner">
                            <input
                              className="l-icon datepicker"
                              type="text"
                              placeholder="Name"
                              required
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="form-group col-lg-6 col-md-6 col-sm-12">
                          <div className="field-inner">
                            <input
                              className="l-icon datepicker"
                              type="tel"
                              pattern="[0-9]+"
                              placeholder="Phone"
                              required
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form-group col-lg-4 col-md-6 col-sm-12">
                          <div className="field-inner">
                            <span className="alt-icon far fa-user"></span>
                            <select
                              value={persons}
                              onChange={handlePersonsChange}
                              className="l-icon"
                              required
                            >
                              <option value="1">1 Person</option>
                              <option value="2">2 Persons</option>
                              <option value="3">3 Persons</option>
                              <option value="4">4 Persons</option>
                              <option value="5">5 Persons</option>
                              <option value="6">6 Persons</option>
                              <option value="7">7 Persons</option>
                              <option value="8">8 Persons</option>
                              <option value="9">9 Persons</option>
                              <option value="10">10 Persons</option>
                            </select>
                            <span className="arrow-icon far fa-angle-down"></span>
                          </div>
                        </div>

                        <div className="form-group col-lg-4 col-md-6 col-sm-12">
                          <div
                            className="field-inner"
                            onClick={() => inputRef.current.showPicker()}
                          >
                            <span className="alt-icon far fa-calendar"></span>
                            <input
                              ref={inputRef}
                              value={date}
                              onChange={handleDateChange}
                              className="l-icon datepicker"
                              type="date"
                              min={minDate}
                              required
                            />
                            <span className="arrow-icon far fa-angle-down"></span>
                          </div>
                        </div>
                        <div className="form-group col-lg-4 col-md-12 col-sm-12">
                          <div className="field-inner">
                            <span className="alt-icon far fa-clock"></span>
                            <select
                              className="l-icon"
                              value={time}
                              onChange={handleTimeChange}
                            >
                              {availableHours.slice(4).map((hour, i) => (
                                <option key={i} value={hour}>
                                  {`${hour}:00`}
                                </option>
                              ))}
                            </select>
                            <span className="arrow-icon far fa-angle-down"></span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-lg-12 col-md-12 col-sm-12">
                        <div className="field-inner">
                          <textarea
                            name="fieldname"
                            placeholder="Message"
                            value={message}
                            onChange={handleMessageChange}
                            maxLength="150"
                          ></textarea>
                        </div>
                      </div>
                      <div className="form-group col-lg-12 col-md-12 col-sm-12">
                        <div className="field-inner">
                          <button
                            type="submit"
                            className="theme-btn btn-style-one clearfix"
                          >
                            <span className="btn-wrap">
                              <span className="text-one">book a table</span>
                              <span className="text-two">book a table</span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="info-col col-lg-4 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="title">
                    <h2>Contact Us</h2>
                  </div>
                  <div className="data">
                    <div className="booking-info">
                      <div className="bk-title">Booking request</div>
                      <div className="bk-no">
                        <a href="tel:+39-064-875-321">+39-064-875-321</a>
                      </div>
                    </div>
                    <div className="separator">
                      <span></span>
                    </div>
                    <ul className="info">
                      <li>
                        <strong>Location</strong>
                        <br />
                        via Giovanni Amendola, 71, Rome Italy
                      </li>
                      <li>
                        <strong>Open Daily</strong>
                        <br />
                        06:00 am - 03:00 am
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Reservation;
