import React from "react";
import "./ReservationCard.css";
const ReservationCard = ({ reservation, deleteReservation }) => {
  const handleDelete = () => {
    deleteReservation(reservation._id);
  };

  return (
    <div className="reservationCard">
      <div>
        <div className="reservationCardContainer">
          <div className="cardItem">
            <p>Reservation ID: </p>
            <p>{reservation?.reservationID}</p>
          </div>
          <div className="cardItem">
            <p>Name: </p>
            <p>{reservation?.name}</p>
          </div>
          <div className="cardItem">
            <p>Phone: </p>
            <p>{reservation?.phone}</p>
          </div>
          <div className="cardItem">
            <p>Date: </p>
            <p>{reservation?.date?.substring(0, 10)}</p>
          </div>
          <div className="cardItem">
            <p>Time: </p>
            <p>{reservation?.time}</p>
          </div>
          <div className="cardItem">
            <p>Persons: </p>
            <p>{reservation?.persons}</p>
          </div>
          <div className="message">
            <p>Message </p>
            {reservation?.message ? (
              <p className="msgTxt">{reservation?.message}</p>
            ) : (
              <p>No Message</p>
            )}
          </div>
        </div>
      </div>

      <div className="trash-icon-container">
        <span onClick={handleDelete} className="trash-icon far fa-trash"></span>
      </div>
    </div>
  );
};

export default ReservationCard;
