import React from "react";
import "./menuItem.css";
import { useNavigate } from "react-router-dom";
function MenuItem({ menuItem, deleteMenuItem }) {
  const navigate = useNavigate();
  // const handleUpdate = () => {};
  const handleDelete = () => {
    deleteMenuItem(menuItem._id);
  };

  return (
    <div className="menuItem">
      <div className="menuItemContainer">
        <div className="imageContainer">
          <img
            src={`http://localhost:8000/assets/${menuItem.image}`}
            alt="menu item"
          />
        </div>
        <div className="itemDetails">
          <span>Name: </span>
          <h3>{menuItem.name}</h3>
        </div>
        <div className="itemDetails">
          <span>Description: </span>
          <p>{menuItem.description}</p>
        </div>
        <div className="itemDetails">
          <span>Price: </span>
          <span>{menuItem.price} €</span>
        </div>
        <div className="itemDetails">
          <span>Category: </span>
          <span>{menuItem.category}</span>
        </div>
      </div>
      <div className="iconsContainer">
        <i
          className="edit far fa-edit"
          onClick={() => navigate(`/admin/editMenu/${menuItem._id}`)}
        ></i>
        <i className="trash-icon far fa-trash" onClick={handleDelete}></i>
      </div>
    </div>
  );
}

export default MenuItem;
