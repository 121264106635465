import React from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

import Iframe from "react-iframe";
import bg25 from "../../assets/images/background/bg-25.png";
import bg6 from "../../assets/images/background/bg-6.png";
import restro from "../../assets/images/about11.jpg";
import { useState } from "react";
import { useRef } from "react";

function Contactbanner() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [request, setRequest] = useState("");
  const [error, setError] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const form = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSending(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE1_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then((response) => {
        console.log("Email sent successfully:", response);
        setIsSent(true);
        setIsSending(false);
      })
      .catch((error) => {
        console.error("Email sending failed:", error);
        setError(
          "An error occurred while sending the email. Please try again later."
        );
        setIsSending(false);
      });
  };
  return (
    <>
      <div className="contact-map">
        <Iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2969.6833259417326!2d12.497508875984094!3d41.89966707123939!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f61a46c6530c1%3A0xc639ea70f1032ee8!2sImperium%20Ristorante!5e0!3m2!1sen!2seg!4v1685007462239!5m2!1sen!2seg"
          width="100%"
          height="500"
          style="border:0;"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></Iframe>
      </div>

      <section className="contact-page">
        <div className="location-center">
          <div className="auto-container">
            <div className="cinfo-box">
              <div className="row clearfix">
                <div className="contactinfo-block col-lg-6 col-md-4 col-sm-12">
                  <div
                    className="inner-box cp-seprator wow fadeInUp"
                    data-wow-duration="1500ms"
                    data-wow-delay="300ms"
                  >
                    <h4>Contact Info</h4>
                    <div className="text">
                      Location: via Giovanni Amendola, 71, Rome Italy
                      <br />
                      <a href="mailto:booking@domainname.com">
                        Email : booking@domainname.com
                      </a>
                    </div>
                    <div className="more-link">
                      <a href="tel:+39064875321">Booking : +39-064-875-321</a>
                    </div>
                  </div>
                </div>
                <div className="contactinfo-block col-lg-6 col-md-4 col-sm-12">
                  <div
                    className="inner-box wow fadeInUp"
                    data-wow-duration="1500ms"
                    data-wow-delay="300ms"
                  >
                    <h4>Open Daily</h4>
                    <div className="text"> 06:00 am - 03:00 am</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="auto-container">
          <div className="c-page-form-box">
            <div className="row clearfix">
              <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                <div className="title-box centered">
                  <h2>Message us</h2>
                  <div className="text desc">
                    Have a question about the our service? We're here to help,
                    contact us today
                  </div>
                </div>
                <div className="default-form reservation-form">
                  <form ref={form} onSubmit={handleSubmit}>
                    <div className="clearfix">
                      <div className="form-group">
                        <div className="field-inner">
                          <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="field-inner">
                          <input
                            type="text"
                            name="email"
                            placeholder="Your Email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group ">
                        <div className="field-inner">
                          <input
                            type="tel"
                            pattern="[0-9]+"
                            name="phone"
                            placeholder="Phone Number"
                            required
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="form-group ">
                        <div className="field-inner">
                          <textarea
                            name="message"
                            placeholder="Special Request"
                            required
                            value={request}
                            onChange={(e) => setRequest(e.target.value)}
                          ></textarea>
                        </div>
                      </div>

                      <div className="form-group">
                        <button
                          type="submit"
                          className="theme-btn btn-style-one clearfix"
                        >
                          <span className="btn-wrap">
                            <span className="text-one">
                              {isSending ? "Sending..." : "Send Your Message"}
                            </span>
                            <span className="text-two">
                              {isSending ? "Sending..." : "Send Your Message"}
                            </span>
                          </span>
                        </button>
                        {error && <p>{error}</p>}
                        {isSent && (
                          <p style={{ color: "#5f6973", fontSize: "35px" }}>
                            Thank you for your message!
                          </p>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="loc-block col-lg-6 col-md-12 col-sm-12">
                <img className="contactImg" src={restro} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contactbanner;
