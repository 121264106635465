import React from "react";
import Bredcom from "../Bredcrumb/Main";
import Admin from "./Admin";

function Main() {
  return (
    <>
      <Bredcom subtitle="Admin Panel" title="Admin Dashboard" />

      <Admin />
    </>
  );
}

export default Main;
