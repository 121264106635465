import React, { createContext, useState } from "react";

export const AdminContext = createContext();

function MyProvider({ children }) {
  const [admin, setAdmin] = useState("");

  const updateAdmin = (newValue) => {
    setAdmin(newValue);
  };

  const state = {
    admin,
    updateAdmin,
  };

  return (
    <AdminContext.Provider value={state}>{children}</AdminContext.Provider>
  );
}

export default MyProvider;
