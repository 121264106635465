import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import bg5 from "../../assets/images/background/bg-5.png";
import bg6 from "../../assets/images/background/bg-6.png";
// import toronto from "../../assets/images/resource/toronto.png";
// import paris from "../../assets/images/resource/paris.png";
// import dubai from "../../assets/images/resource/dubai.png";
// import ny from "../../assets/images/resource/ny.png";
import { useState } from "react";

// <div className="location-center">
//           <div className="auto-container">
//             <div className="row clearfix">
//               <div className="location-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
//                 <div
//                   className="inner-box wow fadeInUp"
//                   data-wow-duration="1500ms"
//                   data-wow-delay="300ms"
//                 >
//                   <div className="icon-box">
//                     <img src={ny} alt="" />
//                   </div>
//                   <h4>New York</h4>
//                   <div className="text">
//                     Restaurant St, Delicious City, NY. <br /> +88-123-123456
//                     <br />
//                     booking@domainname.com
//                   </div>
//                   <div className="more-link">
//                     <Link to="#">get direction</Link>
//                   </div>
//                 </div>
//               </div>

//               <div className="location-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
//                 <div
//                   className="inner-box wow fadeInUp"
//                   data-wow-duration="1500ms"
//                   data-wow-delay="300ms"
//                 >
//                   <div className="icon-box">
//                     <img src={dubai} alt="" />
//                   </div>
//                   <h4>Dubai</h4>
//                   <div className="text">
//                     520, Delicious City, Revenu Db.
//                     <br />
//                     +88-123-123456
//                     <br />
//                     booking@domainname.com
//                   </div>
//                   <div className="more-link">
//                     <Link to="#">get direction</Link>
//                   </div>
//                 </div>
//               </div>

//               <div className="location-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
//                 <div
//                   className="inner-box wow fadeInUp"
//                   data-wow-duration="1500ms"
//                   data-wow-delay="600ms"
//                 >
//                   <div className="icon-box">
//                     <img src={paris} alt="" />
//                   </div>
//                   <h4>Paris</h4>
//                   <div className="text">
//                     589, Delicious Avenue PS.
//                     <br />
//                     +88-123-123456 <br /> booking@domainname.com
//                   </div>
//                   <div className="more-link">
//                     <Link to="#">get direction</Link>
//                   </div>
//                 </div>
//               </div>

//               <div className="location-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
//                 <div
//                   className="inner-box wow fadeInUp"
//                   data-wow-duration="1500ms"
//                   data-wow-delay="900ms"
//                 >
//                   <div className="icon-box">
//                     <img src={toronto} alt="" />
//                   </div>
//                   <h4>Toronto</h4>
//                   <div className="text">
//                     Restaurant St, Delicious City, CA <br /> +88-123-123456{" "}
//                     <br /> booking@domainname.com
//                   </div>
//                   <div className="more-link">
//                     <Link to="#">get direction</Link>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

function Book() {
  const [persons, setPersons] = useState("1");
  const [availableHours, setAvailableHours] = useState([]);
  const [message, setMessage] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [reservationID, setReservationID] = useState("");
  // const [tableId, setTableId] = useState("");
  // const [tables, setTables] = useState([]);
  const navigate = useNavigate();
  const minDate = new Date().toISOString().slice(0, 10);

  const getActualTime = () => {
    const now = new Date();
    const threeAM = new Date(now);
    threeAM.setHours(3, 0, 0, 0);
    if (now.getHours() >= 3) {
      threeAM.setDate(threeAM.getDate() + 1);
    }
    const hours = [];
    while (now < threeAM) {
      hours.push(now.getHours());
      now.setHours(now.getHours() + 1);
    }
    setAvailableHours(hours);
    setTime(hours[1]);
    return hours;
  };

  function generateReservationId() {
    const timestamp = Date.now().toString().slice(-6); // get last 6 digits of current timestamp
    const randomNumber = Math.floor(Math.random() * 1000000)
      .toString()
      .padStart(6, "0"); // generate a random number between 0 and 999999 and pad it with leading zeros to ensure it has 6 digits
    const reservationId = timestamp + randomNumber; // combine timestamp and random number to create a unique ID
    setReservationID(reservationId);
  }

  const reservation = {
    name,
    phone,
    date,
    time,
    persons,
    reservationID,
    // tableId,
    message,
  };
  const inputRef = useRef();

  const handlePersonsChange = (event) => {
    setPersons(event.target.value);
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  // const handleTableChange = (event) => {
  //   setTableId(event.target.value);
  // };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  useEffect(() => {
    // fetch(`${process.env.REACT_APP_API_URL}/api/check-availability`, {
    //   method: "GET",
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setTables(data);
    //     setTableId(data[0]._id);
    //   })
    //   .catch((error) => console.error(error));
    getActualTime();
    generateReservationId();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_API_URL}/api/reservations`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reservation),
    })
      .then((response) => response.json())
      .then((data) => navigate(`/reservation-confirmation/${data._id}`))
      .catch((error) => console.error(error));
  };

  return (
    <>
      <section className="online-reservation inner-page">
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>Reservation</span>
            </div>
            <div className="pattern-image">
              <img
                src={require("../../assets/images/icons/separator.svg").default}
                alt=""
                title=""
              />
            </div>

            <h2>Book A Table</h2>
            <div className="text desc">
              Restaurant will be open for all days, Sunday night will be closed,
              All booking payment is secured with credit card, no charges will
              be aplly for online booking. no refundable.
            </div>
            <div className="text request-info">
              Booking request <a href="tel:+39-064-875-321">+39-064-875-321</a>{" "}
              or fill out the order form
            </div>
          </div>
          <div className="default-form reservation-form">
            <form onSubmit={handleSubmit}>
              <div className="row clearfix">
                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      className="l-icon datepicker"
                      type="text"
                      placeholder="Name"
                      required
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      className="l-icon datepicker"
                      type="tel"
                      pattern="[0-9]+"
                      placeholder="Phone"
                      required
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <span className="alt-icon far fa-user"></span>
                    <select
                      value={persons}
                      onChange={handlePersonsChange}
                      className="l-icon"
                      required
                    >
                      <option value="1">1 Person</option>
                      <option value="2">2 Persons</option>
                      <option value="3">3 Persons</option>
                      <option value="4">4 Persons</option>
                      <option value="5">5 Persons</option>
                      <option value="6">6 Persons</option>
                      <option value="7">7 Persons</option>
                      <option value="8">8 Persons</option>
                      <option value="9">9 Persons</option>
                      <option value="10">10 Persons</option>
                    </select>
                    <span className="arrow-icon far fa-angle-down"></span>
                  </div>
                </div>

                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="field-inner"
                    onClick={() => inputRef.current.showPicker()}
                  >
                    <span className="alt-icon far fa-calendar"></span>
                    <input
                      ref={inputRef}
                      value={date}
                      onChange={handleDateChange}
                      className="l-icon datepicker"
                      type="date"
                      min={minDate}
                      required
                    />
                    <span className="arrow-icon far fa-angle-down"></span>
                  </div>
                </div>
                <div className="form-group col-lg-4 col-md-12 col-sm-12">
                  <div className="field-inner">
                    <span className="alt-icon far fa-clock"></span>
                    <select
                      className="l-icon"
                      value={time}
                      onChange={handleTimeChange}
                    >
                      {availableHours.slice(4).map((hour, i) => (
                        <option key={i} value={hour}>
                          {`${hour}:00`}
                        </option>
                      ))}
                    </select>
                    <span className="arrow-icon far fa-angle-down"></span>
                  </div>
                </div>
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12">
                <div className="field-inner">
                  <textarea
                    name="fieldname"
                    placeholder="Message"
                    value={message}
                    onChange={handleMessageChange}
                    maxLength="150"
                  ></textarea>
                </div>
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12">
                <div className="field-inner">
                  <button
                    type="submit"
                    className="theme-btn btn-style-one clearfix"
                  >
                    <span className="btn-wrap">
                      <span className="text-one">book a table</span>
                      <span className="text-two">book a table</span>
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="map-location-section">
          <div className="auto-container">
            <div className="outer-box">
              <div className="row clearfix">
                <div className="reserv-col col-lg-8 col-md-12 col-sm-12">
                  <div className="inner">
                    <iframe
                      title="location"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2969.6833259417326!2d12.497508875984094!3d41.89966707123939!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f61a46c6530c1%3A0xc639ea70f1032ee8!2sImperium%20Ristorante!5e0!3m2!1sen!2seg!4v1685007462239!5m2!1sen!2seg"
                      width="100%"
                      height="600"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
                <div className="info-col col-lg-4 col-md-12 col-sm-12">
                  <div className="inner">
                    <div className="title">
                      <h2>Rome</h2>
                    </div>
                    <div className="data">
                      <ul className="info">
                        <li>
                          <strong>Contact Us</strong>
                          <br />
                          Location: via Giovanni Amendola,
                          <br /> 71, Rome Italy
                          <br /> Call : +39-064-875-321 <br /> Email :
                          booking@domainname.com{" "}
                        </li>
                        <div className="separator">
                          <span></span>
                        </div>
                        <li>
                          <strong>Open Daily</strong>
                          <br />
                          06:00 am - 03:00am
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Book;
