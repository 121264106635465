import React from "react";
import Contactbanner from "../Mencontactus/Contactbanner";
import Bredcrumb from "../Bredcrumb/Main";
import Img from "../../assets/images/WEBSITE PHOTOS/Different photos/set-of-asian-dishes-2021-12-09-20-31-02-utc.jpg";

function Main() {
  return (
    <>
      <Bredcrumb subtitle="Contact Us" Img={Img} />

      <Contactbanner />
    </>
  );
}

export default Main;
