import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import bgone from "../../../assets/images/background/bg-16.png";
import bgtwo from "../../../assets/images/background/bg-17.png";
import bgthree from "../../../assets/images/background/bg-18.png";
import menuone from "../../../assets/images/resource/menus-1.jpg";
import menutwo from "../../../assets/images/resource/menus-2.jpg";
import appetizersImg from "../../../assets/images/WEBSITE PHOTOS/APPETIZERS/appetizing-spanish-tapas-with-anchovies-2023-03-24-22-52-59-utc.jpg";
import friesImg from "../../../assets/images/WEBSITE PHOTOS/Different photos/french-fries-2021-08-26-18-18-17-utc.jpg";
import firstCourseImg from "../../../assets/images/WEBSITE PHOTOS/Different photos/chicken-chow-mein-meal-2022-02-01-22-39-40-utc.jpg";
import secondCourseImg from "../../../assets/images/WEBSITE PHOTOS/Different photos/delicious-dish-is-fried-quail-2021-12-27-23-28-49-utc.jpg";
import Meat from "../../../assets/images/WEBSITE PHOTOS/Different photos/juicy-steak-with-flavored-butter-2022-01-18-23-39-33-utc.JPG";
import Fish from "../../../assets/images/WEBSITE PHOTOS/Different photos/bbq-roasted-sea-trout-grilled-fish-on-a-grill-wit-2022-01-19-21-55-54-utc.JPG";
import Pizza from "../../../assets/images/WEBSITE PHOTOS/PIZZA/pizza-with-salami-italian-food-2022-01-10-22-54-35-utc.jpg";
import BRUSCHETTAImg from "../../../assets/images/WEBSITE PHOTOS/PIZZA/konstantinas-ladauskas-86vIY4SPr6A-unsplash.jpg";
import sideImg from "../../../assets/images/WEBSITE PHOTOS/Different photos/appetizing-baked-potatoes-with-crunchy-cheese-in-c-2023-04-25-16-06-09-utc.jpg";
import saladImg from "../../../assets/images/WEBSITE PHOTOS/Different photos/buffet-with-a-large-selection-of-vegetable-dishes-2022-11-09-04-59-01-utc.jpg";
import Drink1 from "../../../assets/images/WEBSITE PHOTOS/DRINKS/two-pizzas-and-a-glass-of-beer-and-red-wine-on-a-t-2022-03-07-16-20-02-utc.jpg";
import Drink2 from "../../../assets/images/WEBSITE PHOTOS/DRINKS/people-having-party-sitting-at-table-set-with-wine-2022-02-02-05-06-06-utc.jpg";
import Drink3 from "../../../assets/images/WEBSITE PHOTOS/DRINKS/girls-toasting-with-wine-at-party-2022-01-12-04-37-55-utc.jpg";
import Drink4 from "../../../assets/images/WEBSITE PHOTOS/DRINKS/cocktails-drinks-classic-alcoholic-long-drink-or-2022-01-18-23-38-09-utc.jpg";
import Dessert from "../../../assets/images/WEBSITE PHOTOS/DRINKS/shivansh-sethi-dKT6Q7q2UKs-unsplash.jpg";
function Startermenu() {
  const appetizers = [
    { name: "Stew baby octopus _ Octopus stew", price: "14€" },
    { name: "Seafood trio _ appetizer", price: "15€" },
    { name: "Fresh salmon carpaccio", price: "15€" },
    { name: "Sea Salad", price: "15€" },
    { name: "Ham and Buffalo Mozzarella", price: "14€" },
    { name: "Ham and melon", price: "12€" },
    { name: "Mix of cold cuts", price: "15€" },
    { name: "Bresaola, rocket and parmesan flakes", price: "14€" },
    { name: "Caprese", price: "10€" },
    { name: "Grilled vegetables", price: "10€" },
    { name: "Starter of the day", price: "18€" },
  ];
  const fried = [
    { name: "mixed fried imperium ", price: "10€" },
    { name: "Fillet of codfish ", price: "4€" },
    { name: "Pumpkin flower", price: "3€" },
    { name: "Olive Ascolana", price: "4€" },
    { name: "suppli (1pc)", price: "2€" },
    { name: "Chicken nuggets", price: "6€" },
    { name: "Potato croquette", price: "2€" },
  ];
  const firstCourses = [
    { name: 'Tonnarelli "Imperium"', price: "17€" },
    { name: "Spaghetti with clams", price: "15€" },
    { name: "Scampi cream risotto", price: "14€" },
    { name: "Risotto alla Pescatora with fresh tomato", price: "17€" },
    { name: "Spaghetti Carbonara", price: "12€" },
    { name: "Spaghetti Amatriciana", price: "12€" },
    { name: "Fettuccine Bolognese", price: "14€" },
    { name: "Cannelloni stuffed with ricotta and spinach", price: "14€" },
    { name: "lasagna", price: "14€" },
    { name: "Spaghetti with tomato sauce", price: "10€" },
    { name: "Tonnarelli Cacio e Pepe", price: "12€" },
    { name: "Linguine with lobster", price: "20€" },
    { name: "Linguine with pesto", price: "12€" },
    { name: "Penne all'Arrabbiata", price: "10€" },
    { name: "Porcini Mushroom Fettuccine", price: "14€" },
    { name: "Italian minestrone", price: "10€" },
    { name: "First Course of the Day", price: "15€" },
  ];
  const secondCourses = [
    { name: "fillet with green pepper", price: "25€" },
    { name: "Baked lamb with potatoes", price: "20€" },
    { name: "Baked chicken with potatoes", price: "14€" },
    { name: "Saltimbocca alla Romana", price: "14€" },
    { name: "Escalope with marsala", price: "14€" },
    { name: "chicken alla romana - roman chicken", price: "14€" },
    { name: "Sea bream or sea bass with baked potatoes", price: "20€" },
    { name: "Prawns in Cognac", price: "18€" },
    { name: "Fried calamari and prawns", price: "20€" },
    { name: "Catch of the day", price: "5€" },
  ];
  const grilledMeat = [
    { name: "Mixed grilled meats", price: "30€" },
    { name: "Beef steak", price: "22€" },
    { name: "fillet steak", price: "25€" },
    { name: "Beef entrecote on the bone", price: "22€" },
    { name: "Beef entrecote on the bone", price: "20€" },
    { name: "Veal loin", price: "22€" },
    { name: "Florentine", price: "40€" },
    {
      name: "Sliced ​​beef with cherry tomatoes, rocket and parmesan",
      price: "22€",
    },
    { name: "Sliced ​​beef with balsamic vinegar", price: "22€" },
    { name: "Chicken cut", price: "14€" },
    { name: "Sausage with chicory", price: "15€" },
    { name: "Steakhouse Pork with BBQ Sauce and Mustard", price: "20€" },
  ];
  const grilledFish = [
    { name: "Mixed grilled fish", price: "30€" },
    { name: "Grilled sea bass or sea bream", price: "20€" },
    { name: "Grilled squid", price: "20€" },
    { name: "Fish soup", price: "30€" },
    { name: "Grilled turbot", price: "5€" },
  ];
  const pizzas = [
    {
      name: "Pizza Naples",
      desc: "tomato - mozzarella-anchovies",
      price: "10€",
    },
    { name: "Pizza Marinara", desc: "tomato - garlic - oregano", price: "8€" },
    { name: "Pizza margherita", desc: "tomato mozzarella", price: "10€" },
    {
      name: "Pizza with Mushrooms",
      desc: "tomato - mozzarella - mushrooms",
      price: "11€",
    },
    {
      name: "Raw ham pizza",
      desc: "tomato - mozzarella - raw ham",
      price: "14€",
    },
    {
      name: "Cooked ham pizza",
      desc: "tomato - mozzarella-cooked ham",
      price: "13€",
    },
    {
      name: "Pizza with Salami",
      desc: "tomato - mozzarella - salami",
      price: "15€",
    },
    {
      name: 'Pizza "Imperium"',
      desc: "pachino-buffalo-basil sauce - parmesan flakes",
      price: "16€",
    },
    {
      name: "Pizza Sausage and Potatoes",
      desc: "tomato - mozzarella - sausage - potatoes",
      price: "15€",
    },
    {
      name: "Buffalo mozzarella pizza",
      desc: "tomato - buffalo mozzarella",
      price: "12€",
    },
    { name: "Pizza Four Cheeses", desc: "four cheese tomato", price: "12€" },
    {
      name: "Capricciosa pizza",
      desc: "mushrooms, olives, mozzarella, tomato, artichokes, ham",
      price: "14€",
    },
  ];
  const BRUSCHETTA = [
    { name: "bruschetta poker", price: "10€" },
    { name: "tomato", price: "4€" },
    { name: "Artichokes", price: "4€" },
    { name: "TO the ham", price: "5€" },
    { name: "Olives", price: "4€" },
    { name: "Mascarpone and Gorgonzola", price: "5€" },
    { name: "salmon", price: "4€" },
  ];
  const sides = [
    { name: "Artichokes alla giudia (fried)", price: "6€" },
    { name: "Baked potatoes", price: "6€" },
    { name: "French fries", price: "6€" },
    { name: "Spinach", price: "6€" },
    { name: "Chicory", price: "6€" },
    { name: "Artichokes alla Romana", price: "6€" },
  ];
  const salads = [
    { name: "caesar salad", price: "15€" },
    { name: "Capricious", price: "13€" },
    { name: "Rocket, salmon and buffalo", price: "15€" },
    { name: "IMPERIUM", price: "18€" },
    { name: "Greek salad", price: "12€" },
  ];
  const beer = [
    { name: "Draft beer XXL 1,5 It", price: "20€" },
    { name: "Extra large draft beer 1 It", price: "15€" },
    { name: "Medium draft beer", price: "7€" },
    { name: "Small draft beer", price: "5€" },
    { name: "Medium red draft beer", price: "8€" },
    { name: "Small red draft beer", price: "5€" },
    { name: "National Beer in 33 cl bottle", price: "5€" },
    { name: "Foreign Beer in 33 cl bottle", price: "6€" },
  ];
  const drinks = [
    { name: "Large mineral water", price: "4€" },
    { name: "Small mineral water", price: "2.5€" },
    { name: "Coca Cola 33cl / Fanta 33cl / Sprite 33cl", price: "4€" },
    { name: "Cappuccino", price: "4€" },
    { name: "Coffee", price: "2€" },
    { name: "THE", price: "4€" },
    { name: "ICE TEA", price: "4€" },
    { name: "RED BULL", price: "5€" },
    { name: "SPIRITS", price: "6€" },
    { name: "COCKTAIL", price: "9€" },
  ];
  const white = [
    { name: "house white", price: "15€" },
    { name: "house white (Frascati)", price: "10€" },
    { name: "East! East!! East!!!", price: "20€" },
    { name: "Moscato d'Asti", price: "35€" },
    { name: "Piont Grey", price: "30€" },
    { name: "Falanghina", price: "30€" },
    { name: "chardonnay", price: "30€" },
    { name: "Verdicchio", price: "30€" },
    { name: "Muller Thurgau", price: "30€" },
    { name: "Prosecco", price: "35€" },
  ];
  const red = [
    { name: "house red", price: "15€" },
    { name: "House red", price: "10€" },
    { name: "Lambrusco", price: "30€" },
    { name: "Barbera", price: "30€" },
    { name: "Chianti", price: "30€" },
    { name: "Valpolicella", price: "30€" },
    { name: "Rose", price: "30€" },
    { name: "Cabernet", price: "30€" },
    { name: "Barolo", price: "55€" },
    { name: "Montepulciano d'Abruzzo", price: "30€" },
    { name: "Scansano", price: "35€" },
    { name: "Brunello di Montalcino", price: "55€" },
  ];
  const sweets = [
    { name: "Tiramisu", price: "5€" },
    { name: "PANNA COTTA", price: "6€" },
    { name: "CREME CARAMEL", price: "6€" },
    { name: "CRUMBLED", price: "7€" },
    { name: "HOT CHOCOLATE CAKE", price: "8€" },
    { name: "PROFITTROL", price: "7€" },
    { name: "ICE CREAMS", price: "6€" },
    { name: "FRUIT SALAD", price: "6€" },
    { name: "SEASONAL FRUIT", price: "6€" },
  ];
  useEffect(() => {
    const href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <>
      <div id="main">
        <section className="menu-one">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>MAIN MENU</span>
              </div>

              <div className="pattern-image">
                <img
                  src={
                    require("../../../assets/images/icons/separator.svg")
                      .default
                  }
                  alt="mySvgImage"
                />
              </div>

              <h2>Appetizers</h2>
            </div>

            <div className="row clearfix">
              <div className="image-col col-lg-7 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="image">
                    <img src={appetizersImg} alt="" />
                  </div>
                </div>
              </div>
              <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                <div className="inner">
                  {appetizers.map((appetizer, i) => (
                    <div className="dish-block">
                      <div className="inner-box">
                        <div className="title clearfix">
                          <div className="ttl clearfix">
                            <h5>
                              <Link to="#">{appetizer.name}</Link>
                            </h5>
                          </div>
                        </div>
                        <div className="text desc">
                          <Link to="#"></Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="menu-one alternate">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>Main Menu</span>
              </div>

              <div className="pattern-image">
                <img
                  src={
                    require("../../../assets/images/icons/separator.svg")
                      .default
                  }
                  alt="mySvgImage"
                />
              </div>

              <h2>Fried</h2>
            </div>

            <div className="row clearfix">
              <div className="image-col col-lg-7 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="image">
                    <img src={friesImg} alt="" />
                  </div>
                </div>
              </div>
              <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                <div className="inner">
                  {fried.map((fried) => (
                    <div className="dish-block">
                      <div className="inner-box">
                        <div className="title clearfix">
                          <div className="ttl clearfix">
                            <h5>
                              <Link to="#">{fried.name}</Link>
                            </h5>
                          </div>
                        </div>
                        <div className="text desc">
                          <Link to="#"></Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="menu-one">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>Main Menu</span>
              </div>

              <div className="pattern-image">
                <img
                  src={
                    require("../../../assets/images/icons/separator.svg")
                      .default
                  }
                  alt="mySvgImage"
                />
              </div>

              <h2>First Courses</h2>
            </div>

            <div className="row clearfix">
              <div className="image-col col-lg-8 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="image">
                    <img src={firstCourseImg} alt="" />
                  </div>
                </div>
              </div>
              <div className="menu-col col-lg-4 col-md-12 col-sm-12">
                <div className="inner">
                  {firstCourses.map((first) => (
                    <div className="dish-block">
                      <div className="inner-box">
                        <div className="title clearfix">
                          <div className="ttl clearfix">
                            <h5>
                              <Link to="#">{first.name}</Link>
                            </h5>
                          </div>
                        </div>
                        <div className="text desc">
                          <Link to="#"></Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="menu-one alternate">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>Main Menu</span>
              </div>

              <div className="pattern-image">
                <img
                  src={
                    require("../../../assets/images/icons/separator.svg")
                      .default
                  }
                  alt="mySvgImage"
                />
              </div>

              <h2>Second Courses</h2>
            </div>

            <div className="row clearfix">
              <div className="image-col col-lg-7 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="image">
                    <img src={secondCourseImg} alt="" />
                  </div>
                </div>
              </div>
              <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                <div className="inner">
                  {secondCourses.map((second) => (
                    <div className="dish-block">
                      <div className="inner-box">
                        <div className="title clearfix">
                          <div className="ttl clearfix">
                            <h5>
                              <Link to="#">{second.name}</Link>
                            </h5>
                          </div>
                        </div>
                        <div className="text desc">
                          <Link to="#"></Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="menu-one">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>Main Menu</span>
              </div>

              <div className="pattern-image">
                <img
                  src={
                    require("../../../assets/images/icons/separator.svg")
                      .default
                  }
                  alt="mySvgImage"
                />
              </div>

              <h2>Grilled Meat</h2>
            </div>

            <div className="row clearfix">
              <div className="image-col col-lg-8 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="image">
                    <img src={Meat} alt="" />
                  </div>
                </div>
              </div>
              <div className="menu-col col-lg-4 col-md-12 col-sm-12">
                <div className="inner">
                  {grilledMeat.map((meat) => (
                    <div className="dish-block">
                      <div className="inner-box">
                        <div className="title clearfix">
                          <div className="ttl clearfix">
                            <h5>
                              <Link to="#">{meat.name}</Link>
                            </h5>
                          </div>
                        </div>
                        <div className="text desc">
                          <Link to="#"></Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="menu-one alternate">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>Main Menu</span>
              </div>

              <div className="pattern-image">
                <img
                  src={
                    require("../../../assets/images/icons/separator.svg")
                      .default
                  }
                  alt="mySvgImage"
                />
              </div>

              <h2>Grilled Fish</h2>
            </div>

            <div className="row clearfix">
              <div className="image-col col-lg-7 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="image">
                    <img src={Fish} alt="" />
                  </div>
                </div>
              </div>
              <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                <div className="inner">
                  {grilledFish.map((fish) => (
                    <div className="dish-block">
                      <div className="inner-box">
                        <div className="title clearfix">
                          <div className="ttl clearfix">
                            <h5>
                              <Link to="#">{fish.name}</Link>
                            </h5>
                          </div>
                        </div>
                        <div className="text desc">
                          <Link to="#"></Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div id="pizza">
        <section className="menu-one">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>Delicious</span>
              </div>

              <div className="pattern-image">
                <img
                  src={
                    require("../../../assets/images/icons/separator.svg")
                      .default
                  }
                  alt="mySvgImage"
                />
              </div>

              <h2>Pizza</h2>
            </div>

            <div className="row clearfix">
              <div className="image-col col-lg-7 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="image">
                    <img src={Pizza} alt="" />
                  </div>
                </div>
              </div>
              <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                <div className="inner">
                  {pizzas.map((pizza, i) => (
                    <div className="dish-block">
                      <div className="inner-box">
                        <div className="title clearfix">
                          <div className="ttl clearfix">
                            <h5>
                              <Link to="#">{pizza.name}</Link>
                            </h5>
                          </div>
                        </div>
                        <div className="text desc">
                          <Link to="#">{pizza.desc}</Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="menu-one alternate">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>Delicious</span>
              </div>

              <div className="pattern-image">
                <img
                  src={
                    require("../../../assets/images/icons/separator.svg")
                      .default
                  }
                  alt="mySvgImage"
                />
              </div>

              <h2>BRUSCHETTA</h2>
            </div>

            <div className="row clearfix">
              <div className="image-col col-lg-7 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="image">
                    <img src={BRUSCHETTAImg} alt="" />
                  </div>
                </div>
              </div>
              <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                <div className="inner">
                  {BRUSCHETTA.map((item) => (
                    <div className="dish-block">
                      <div className="inner-box">
                        <div className="title clearfix">
                          <div className="ttl clearfix">
                            <h5>
                              <Link to="#">{item.name}</Link>
                            </h5>
                          </div>
                        </div>
                        <div className="text desc">
                          <Link to="#"></Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div id="side">
        <section className="menu-one">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>Side Dishes & Salads</span>
              </div>

              <div className="pattern-image">
                <img
                  src={
                    require("../../../assets/images/icons/separator.svg")
                      .default
                  }
                  alt="mySvgImage"
                />
              </div>

              <h2>SIDE DISHES</h2>
            </div>

            <div className="row clearfix">
              <div className="image-col col-lg-7 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="image">
                    <img src={sideImg} alt="" />
                  </div>
                </div>
              </div>
              <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                <div className="inner">
                  {sides.map((side, i) => (
                    <div className="dish-block">
                      <div className="inner-box">
                        <div className="title clearfix">
                          <div className="ttl clearfix">
                            <h5>
                              <Link to="#">{side.name}</Link>
                            </h5>
                          </div>
                        </div>
                        <div className="text desc">
                          <Link to="#"></Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="menu-one alternate">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>Side Dishes & Salads</span>
              </div>

              <div className="pattern-image">
                <img
                  src={
                    require("../../../assets/images/icons/separator.svg")
                      .default
                  }
                  alt="mySvgImage"
                />
              </div>

              <h2>Salads</h2>
            </div>

            <div className="row clearfix">
              <div className="image-col col-lg-7 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="image">
                    <img src={saladImg} alt="" />
                  </div>
                </div>
              </div>
              <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                <div className="inner">
                  {salads.map((salad) => (
                    <div className="dish-block">
                      <div className="inner-box">
                        <div className="title clearfix">
                          <div className="ttl clearfix">
                            <h5>
                              <Link to="#">{salad.name}</Link>
                            </h5>
                          </div>
                        </div>
                        <div className="text desc">
                          <Link to="#"></Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div id="drinks">
        <section className="menu-one">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>Drinks & Sweets</span>
              </div>

              <div className="pattern-image">
                <img
                  src={
                    require("../../../assets/images/icons/separator.svg")
                      .default
                  }
                  alt="mySvgImage"
                />
              </div>

              <h2>Beer</h2>
            </div>

            <div className="row clearfix">
              <div className="image-col col-lg-7 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="image">
                    <img src={Drink1} alt="" />
                  </div>
                </div>
              </div>
              <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                <div className="inner">
                  {beer.map((ber, i) => (
                    <div className="dish-block">
                      <div className="inner-box">
                        <div className="title clearfix">
                          <div className="ttl clearfix">
                            <h5>
                              <Link to="#">{ber.name}</Link>
                            </h5>
                          </div>
                        </div>
                        <div className="text desc">
                          <Link to="#"></Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="menu-one alternate">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>Drinks & Sweets</span>
              </div>

              <div className="pattern-image">
                <img
                  src={
                    require("../../../assets/images/icons/separator.svg")
                      .default
                  }
                  alt="mySvgImage"
                />
              </div>

              <h2>Drinks</h2>
            </div>

            <div className="row clearfix">
              <div className="image-col col-lg-7 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="image">
                    <img src={Drink4} alt="" />
                  </div>
                </div>
              </div>
              <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                <div className="inner">
                  {drinks.map((drink) => (
                    <div className="dish-block">
                      <div className="inner-box">
                        <div className="title clearfix">
                          <div className="ttl clearfix">
                            <h5>
                              <Link to="#">{drink.name}</Link>
                            </h5>
                          </div>
                        </div>
                        <div className="text desc">
                          <Link to="#"></Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="menu-one">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>Drinks & Sweets</span>
              </div>

              <div className="pattern-image">
                <img
                  src={
                    require("../../../assets/images/icons/separator.svg")
                      .default
                  }
                  alt="mySvgImage"
                />
              </div>

              <h2>White Wines</h2>
            </div>

            <div className="row clearfix">
              <div className="image-col col-lg-7 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="image">
                    <img src={Drink2} alt="" />
                  </div>
                </div>
              </div>
              <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                <div className="inner">
                  {white.map((w, i) => (
                    <div className="dish-block">
                      <div className="inner-box">
                        <div className="title clearfix">
                          <div className="ttl clearfix">
                            <h5>
                              <Link to="#">{w.name}</Link>
                            </h5>
                          </div>
                        </div>
                        <div className="text desc">
                          <Link to="#"></Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="menu-one alternate">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>Drinks & Sweets</span>
              </div>

              <div className="pattern-image">
                <img
                  src={
                    require("../../../assets/images/icons/separator.svg")
                      .default
                  }
                  alt="mySvgImage"
                />
              </div>

              <h2>Red Wine</h2>
            </div>

            <div className="row clearfix">
              <div className="image-col col-lg-7 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="image">
                    <img src={Drink3} alt="" />
                  </div>
                </div>
              </div>
              <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                <div className="inner">
                  {red.map((r) => (
                    <div className="dish-block">
                      <div className="inner-box">
                        <div className="title clearfix">
                          <div className="ttl clearfix">
                            <h5>
                              <Link to="#">{r.name}</Link>
                            </h5>
                          </div>
                        </div>
                        <div className="text desc">
                          <Link to="#"></Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="menu-one">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span>Drinks & Sweets</span>
              </div>

              <div className="pattern-image">
                <img
                  src={
                    require("../../../assets/images/icons/separator.svg")
                      .default
                  }
                  alt="mySvgImage"
                />
              </div>

              <h2>Sweets</h2>
            </div>

            <div className="row clearfix">
              <div className="image-col col-lg-7 col-md-12 col-sm-12">
                <div className="inner">
                  <div className="image">
                    <img src={Dessert} alt="" />
                  </div>
                </div>
              </div>
              <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                <div className="inner">
                  {sweets.map((sweet, i) => (
                    <div className="dish-block">
                      <div className="inner-box">
                        <div className="title clearfix">
                          <div className="ttl clearfix">
                            <h5>
                              <Link to="#">{sweet.name}</Link>
                            </h5>
                          </div>
                        </div>
                        <div className="text desc">
                          <Link to="#"></Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Startermenu;
