import React from "react";
import Bredcom from "../Bredcrumb/Main";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminContext } from "../../Provider/MyProvider";
import { useContext } from "react";
import "./LoginForm.css";

function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //   const [admin, setAdmin] = useState(null);
  const [error, setError] = useState("");
  const { updateAdmin } = useContext(AdminContext);
  const navigate = useNavigate();

  const loginData = {
    email,
    password,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data === "Invalid Credentials") {
          setError(data);
        } else {
          updateAdmin(data);
          localStorage.setItem("token", data.token);

          navigate("/admin");
        }
      })
      .catch((error) => console.error(error));
  };
  return (
    <>
      <Bredcom subtitle="Dashboard" title="" />
      <div className="menuItemForm">
        <h2>Login</h2>
        <form className="form" onSubmit={handleSubmit}>
          <label for="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter Your Email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="menuInput"
          ></input>
          <label for="desc">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Enter Your Password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="menuInput"
          ></input>
          {error && <p>{error}</p>}

          <button className="menuBtn" type="submit">
            Submit
          </button>
        </form>
      </div>
    </>
  );
}

export default LoginForm;
