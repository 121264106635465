import React from "react";
import Backgroundimage from "../../assets/images/WEBSITE PHOTOS/Different photos/appetizers-with-differents-antipasti-2022-09-29-22-35-06-utc.jpg";
import OwlCarousel from "react-owl-carousel";
import quotesone from "../../assets/images/icons/quotes-1.png";
import authorthumb from "../../assets/images/resource/author-thumb-4.jpg";
import authorthumbone from "../../assets/images/resource/author-thumb-5.jpg";
import authorthumbtwo from "../../assets/images/resource/author-thumb-6.jpg";
import author1 from "../../assets/images/author1.png";
import author2 from "../../assets/images/author2.png";
import author3 from "../../assets/images/author3.png";
import author4 from "../../assets/images/author4.png";
import author5 from "../../assets/images/author5.png";

function Testimonialsabout() {
  const reviews = [author1, author2, author3, author4, author5];

  const show = {
    loop: true,
    margin: 50,
    nav: true,
    smartSpeed: 700,
    autoplay: true,
    autoplayTimeout: 7000,
    navText: [
      '<span class="icon fa-light fa-angle-left"></span>',
      '<span class="icon fa-light fa-angle-right"></span>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      768: {
        items: 2,
        margin: 30,
      },
      992: {
        items: 2,
        margin: 30,
      },
      1200: {
        items: 3,
      },
    },
  };

  return (
    <>
      <section className="testimonials-two">
        <div
          className="image-layer"
          style={{ backgroundImage: `url(${Backgroundimage})` }}
        ></div>
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>testimonials</span>
            </div>
            <div className="pattern-image">
              <img
                src={require("../../assets/images/icons/separator.svg").default}
                alt="mySvgImage"
              />
            </div>
            <h2>What People Are Saying</h2>
          </div>

          <div className="carousel-box" show={show}>
            <OwlCarousel
              className="testimonial-slider owl-theme owl-carousel"
              loop
              margin={50}
              {...show}
            >
              {reviews.map((review) => (
                <div className="slide-item item">
                  <div className="slide-content">
                    <div className="quotes"></div>
                    <div className="text quote-text">
                      <img
                        src={review}
                        alt="review"
                        style={{ height: "300px" }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonialsabout;
