import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import "./SpecialDish.css";
function SpecialDish() {
  const [specialDish, setSpecialDish] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/special-dish`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => setSpecialDish(data[0]))
      .catch((error) => console.error(error));
  }, []);
  return (
    <div className="specialDish">
      <div className="specialDishContainer">
        <div className="imgContainer">
          <img
            src={`${process.env.REACT_APP_API_URL}/assets/${specialDish?.image}`}
            alt=""
          />
        </div>
        <div className="detailsContainer">
          <div className="detailsItem">
            <p className="detailsText">Name:</p>
            <p className="detailsText2">{specialDish?.name}</p>
          </div>
          <div className="">
            <p className="detailsText">Description:</p>

            <p className="detailsP">{specialDish?.description}</p>
          </div>
          <div className="detailsItem">
            <p className="detailsText">Category:</p>

            <p className="detailsText2">{specialDish?.category}</p>
          </div>
          <div className="detailsItem">
            <p className="detailsText">Price:</p>

            <p className="detailsText2">${specialDish?.price}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpecialDish;
