import React from "react";
import Videosection from "../Menhome/Videosection";
import Special from "../Menhome/Special";
import Contact from "../Menhome/Contact";
import Banner from "../Menhome/Banner";
import Offer from "../Menhome/Offer";
import Story from "../Menhome/Story";
import Specialdish from "../Menhome/Specialdish";
import Menusection from "../Menhome/Menusection";
import Whyus from "../Menhome/Whyus";
import Teem from "../Menhome/Teem";
// import Reacntupdate from "../Menhome/Recntupdates";

function Main() {
  return (
    <>
      <Banner />
      <Offer />
      <Story />

      <Contact />
      <Whyus />
      <Videosection />
    </>
  );
}

export default Main;
