import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
// import { useTranslation } from "react-i18next";

function Special() {
  const [menuItems, setMenuItems] = useState([]);
  // const { t } = useTranslation();

  const options = {
    items: 4,
    rewind: true,
    loop: true,
    smartSpeed: 700,
    autoplay: true,
    autoplayTimeout: 7000,
    navText: [
      '<span class="icon fa-light fa-angle-left"></span>',
      '<span class="icon fa-light fa-angle-right"></span>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      768: {
        items: 2,
        margin: 30,
      },
      992: {
        items: 3,
        margin: 30,
      },
      1200: {
        items: 4,
      },
    },
  };
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/menus`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => setMenuItems(data))
      .catch((error) => console.error(error));
  }, []);

  return (
    <>
      <section className="special-offer">
        <div className="outer-container">
          <div className="auto-container">
            <div className="title-box centered">
              <div className="subtitle">
                <span> SPECIAL OFFER</span>
              </div>

              <div className="pattern-image">
                <img
                  src={
                    require("../../assets/images/icons/separator.svg").default
                  }
                  alt="mySvgImage"
                />
              </div>

              <h2> Best Specialties</h2>
            </div>

            <OwlCarousel
              className="dish-gallery-slider owl-carousel owl-theme"
              margin={30}
              nav
              options={options}
            >
              {menuItems?.map((item) => (
                <div className="offer-block-two">
                  <div className="inner-box">
                    <div className="image">
                      <Link to="/">
                        <img
                          src={`${process.env.REACT_APP_API_URL}/assets/${item.image}`}
                          alt=""
                        />
                      </Link>
                    </div>
                    <h4>
                      <Link to="#">{item.name}</Link>
                    </h4>
                    <div className="text desc">{item.description}</div>
                  </div>
                </div>
              ))}
            </OwlCarousel>

            <div className="lower-link-box text-center">
              <Link to="/menu" className="theme-btn btn-style-two clearfix">
                <span className="btn-wrap">
                  <span className="text-one">view all menu</span>
                  <span className="text-two">view all menu</span>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Special;
