import React from "react";
import Confirmation from "../Menhome/Confirmation";
import Bredcom from "../Bredcrumb/Main";

function Main() {
  return (
    <>
      <Bredcom subtitle="Confirmed" title="We Are Waiting For You" />

      <Confirmation />
    </>
  );
}

export default Main;
