import React from "react";
import "./CategoryForm.css";
import { useState } from "react";
import Bredcom from "../Bredcrumb/Main";
import { useNavigate } from "react-router-dom";

function SpecialDishForm() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("morning");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("category", category);
    formData.append("price", price);
    formData.append("image", image);
    fetch(`${process.env.REACT_APP_API_URL}/api/special-dish`, {
      method: "POST",

      body: formData,
    })
      .then((response) => response.json())
      .then(() => navigate("/admin"))
      .catch((error) => console.error(error));
  };
  return (
    <>
      <Bredcom subtitle="Admin Panel" title="Admin Dashboard" />
      <div className="menuItemForm">
        <h2>Change Today's Special Dish</h2>
        <form className="form" onSubmit={handleSubmit}>
          <label for="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Enter Menu Item Name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="menuInput"
          ></input>
          <label for="desc">Description:</label>
          <input
            type="text"
            id="desc"
            name="desc"
            placeholder="Enter Menu Item Description"
            required
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="menuInput"
          ></input>
          <label for="category">Category:</label>
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="menuInput"
          >
            <option value="morning">Morning</option>
            <option value="lunch">Lunch</option>
            <option value="dinner">Dinner</option>
          </select>
          <label for="price">Price:</label>
          <input
            type="text"
            id="price"
            name="price"
            placeholder="Enter Menu Item Price"
            required
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="menuInput"
          />
          <label for="image">Image:</label>
          <input
            type="file"
            id="image"
            name="image"
            placeholder="Upload Image"
            required
            onChange={(e) => setImage(e.target.files[0])}
            className="menuInput"
          />
          <button className="menuBtn" type="submit">
            Submit
          </button>
        </form>
      </div>
    </>
  );
}

export default SpecialDishForm;
