import React from "react";
// import Backgroundimage from "../../assets/images/background/image-4.jpg";
import { Link } from "react-router-dom";
import logonew from "../../assets/images/logo.png";
import Imperium from "../../assets/images/LOGO WHITE.png";
import { useTranslation } from "react-i18next";
function Main() {
  const { t } = useTranslation();
  return (
    <>
      <footer className="main-footer">
        <div className="image-layer"> </div>
        <div className="upper-section">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="footer-col info-col col-lg-6 col-md-12 col-sm-12">
                <div
                  className="inner wow fadeInUp"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <div className="content">
                    <div className="logo">
                      <Link to="/" title="Imperium ">
                        <img src={Imperium} alt="" title="Imperium" />
                      </Link>
                    </div>
                    <div className="info">
                      <ul>
                        <li>Location: via Giovanni Amendola, 71, Rome Italy</li>
                        <li>
                          <a href="mailto:booking@domainname.com">
                            booking@domainname.com
                          </a>
                        </li>
                        <li>
                          <a href="tel:+39064875321">
                            Booking request : +39-064-875-321
                          </a>
                        </li>
                        <li>Open Daily: 06:00 am - 03:00 am</li>
                      </ul>
                    </div>
                    <div className="separator">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer-col links-col col-lg-3 col-md-6 col-sm-12">
                <div
                  className="inner wow fadeInLeft"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <ul className="links">
                    <li>
                      {" "}
                      <Link to="/">HOME</Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="/menu">MENU</Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="/about">ABOUT US</Link>
                    </li>

                    <li>
                      {" "}
                      <Link to="/contact">OUR CHEFS</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="footer-col links-col last col-lg-3 col-md-6 col-sm-12">
                <div
                  className="inner wow fadeInRight"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <ul className="links">
                    <li>
                      {" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/Imperium753acRestaurantRome/"
                      >
                        facebook
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.instagram.com/imperium753ac_restaurant.rome/"
                      >
                        instagram
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.google.com/maps/dir/30.0329331,31.4341788/41.899704,12.5000872/@36.3163132,5.3133174,4z/data=!3m1!4b1!4m4!4m3!1m1!4e1!1m0"
                      >
                        Google map
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="auto-container">
            <div className="copyright">
              &copy; 2023 Ristorante Imperium. All Rights Reserved
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Main;
