import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Header from "../components/Header/Main";
import Footer from "../components/Footer/Main";
import Home from "../components/Home/Main";
import About from "../components/About/Main";
import Confirmation from "../components/Confirmation/Main";
import Chefs from "../components/Chef/Main";
import Contact from "../components/Contact/Main";
import Menu from "../components/Menu/Main";
// import Menuone from "../components/Menu/Menuone";
// import Menutwo from "../components/Menu/Menutwo";
// import Menuthree from "../components/Menu/Menuthree";
// import Menufour from "../components/Menu/Menufour";
import Book from "../components/Book/Main";
// import Scrool from '../components/Menhome/Scrool';
// import Hometwo from "../components/Headertwo/Main";
// import Homethree from "../components/Homethree/Homethree";
import Admin from "../components/Admin/Main";
import CategoryForm from "../components/Admin/CategoryForm";
import LoginForm from "../components/Admin/LoginForm";
import EditForm from "../components/Admin/EditForm";
import SpecialDishForm from "../components/Admin/SpecialDishForm";
import NotFound from "../components/NotFound/NotFound";
// import { AdminContext } from "../Provider/MyProvider";
// import { useContext } from "react";

function Index() {
  const [homepage, sethomepage] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/hometwo") {
      sethomepage(false);
    } else {
      sethomepage(true);
    }
  }, [location]);

  // const location = useLocation()
  const path = location.pathname;
  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);
  // const { admin } = useContext(AdminContext);
  const token = localStorage.getItem("token");

  return (
    <>
      {homepage && <Header />}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />

        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/reservation" element={<Book />} />
        <Route path="/login" element={token ? <Admin /> : <LoginForm />} />
        <Route
          path="/admin"
          element={token ? <Admin /> : <Navigate to="/login" replace />}
        />
        <Route
          path="/admin/addMenu"
          element={token ? <CategoryForm /> : <Navigate to="/login" replace />}
        />
        <Route
          path="/admin/editMenu/:menuId"
          element={token ? <EditForm /> : <Navigate to="/login" replace />}
        />
        <Route path="/admin/addSpecial" element={<SpecialDishForm />} />
        <Route
          path="/reservation-confirmation/:reservationId"
          element={<Confirmation />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>

      <Footer />
    </>
  );
}

export default Index;
